
@mixin select-none {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin select-any {
    -moz-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
}


@mixin heading-style {
    font-family: 'Noto Serif', serif;
    font-display: block;
    font-weight: 500;
    word-spacing: normal;
    letter-spacing: 0.03em;
    line-height: 1em;
    font-kerning: normal;
    margin: 0.3em 0;
}

@mixin text-style {
    font-family: "Noto Sans Mono", monospace;
    font-weight: 400;
    letter-spacing: initial;
    line-height: 1.2em;
    font-kerning: normal;
}

@mixin input-style {
    background-color: transparent;
    border-color: var(--dark-accent-color);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    box-sizing: border-box;
    height: var(--inputHeight);
    @include text-style;
}

@mixin select-value {
    color: var(--this-white);
    border: 1px solid var(--accent-color);
    background-color: var(--tr-accent-color);
    border-radius: 4px;
    padding-right: 0.6em;
    padding-left: 0.6em;
    margin-right: 1px;
}


@mixin link-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-external-link' width='32' height='32' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23002554' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5' /%3E%3Cline x1='10' y1='14' x2='20' y2='4' /%3E%3Cpolyline points='15 4 20 4 20 9' /%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1em;
}

@mixin in-button-icon {
    display: inline-block;
    stroke: var(--this-white);
    width: 1.3em;
    height: 1.3em;
    transform-origin: center;
    transform: translateY(10%);
    // opacity: 0.7;
    margin: -0.2em 0 -0.2em 0;
    // transition: opacity var(--ani-speed) ease-in-out;
}

@mixin land-object {
    position: relative;
    color: var(--dark-shades) !important;
    display: block;
    @include select-value;
    margin: 1ex;
    background-color: transparent;
    border-color: var(--light-accent-color);
    padding: 0;

    &:nth-of-type(1) {
      margin-top: 0;
    } 
    
    &:last-of-type {
      margin-bottom: 0;
    }    

    p {
      overflow: hidden;
      padding: calc(var(--inputHeight) / 2 - 1em);
      margin: 0;
    }
}

@mixin inner-active-svg {
    svg {
        transition: all linear var(--ani-speed);
        color: var(--tr-accent-color);
    }

    &:hover, :focus {

        svg {
            color: var(--accent-color);

            filter: drop-shadow(
            0 0.05em 0.16em var(--tr-accent-color)
            );
            
        }

    }

    &:focus {
        outline: none;
    }
  
}